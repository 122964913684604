@use '../../../sass/variables.scss' as *;
@use '../../../sass/mixins.scss' as *;

@property --bgGrdient1 {
  syntax: '<color>';
  inherits: true;
  initial-value: rgba(0, 0, 0);
}

@property --bgGrdient2 {
  syntax: '<color>';
  inherits: true;
  initial-value: rgba(0, 0, 0);
}

@property --grediantSize {
  syntax: '<percentage>';
  inherits: true;
  initial-value: 0%;
}

.animating-on-transition {
  --transitionAmount: 2s;
  background: linear-gradient(
    90deg,
    var(--bgGrdient1) var(--grediantSize),
    var(--bgGrdient2) 100%
  );

  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  z-index: 99;

  transition: --bgGrdient1 var(--transitionAmount) var(--base-animiation-Timing),
    --bgGrdient2 var(--transitionAmount) var(--base-animiation-Timing),
    --grediantSize var(--transitionAmount) var(--base-animiation-Timing),
    z-index var(--transitionAmount);

  &.animate-page {
    --bgGrdient1: rgba(0, 0, 0, 0);
    --bgGrdient2: rgba(0, 0, 0, 1);
    --grediantSize: 100%;
    z-index: -1;
  }
}

.innerpage {
  min-height: 100vh;
  max-height: max-content;

  @include lg {
    min-height: 100vh;
    max-height: 100%;
  }

  &--destinations,
  &--crew,
  &--technology {
    min-height: 100vh;
    max-height: max-content;

    @include lg {
      min-height: 100vh;
      max-height: 100%;
    }

    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  //  Destinations
  &--destinations {
    background-image: url('../../../assets/destinations/background-destinations-mobile.jpg');

    @include md {
      background-image: url('../../../assets/destinations/background-destinations-tablet.jpg');
    }

    @include lg {
      background-image: url('../../../assets/destinations/background-destinations-desktop.jpg');
    }

    &__tab-img-wrapper {
      min-width: 170px;
      min-height: 170px;
      img {
        width: 170px;
        height: auto;
      }
      @include md {
        min-width: 300px;
        min-height: 300px;
        img {
          width: 300px;
        }
      }
      @include lg {
        min-width: 375px;
        min-height: 375px;
        img {
          width: 375px;
        }
      }

      @include xl {
        min-width: 375px;
        min-height: 445px;
        img {
          width: 445px;
        }
      }
    }

    &__tab-text-content {
      width: 100%;

      @include lg {
        width: auto;
      }
    }

    &__tab-description {
      max-inline-size: 41ch;
      height: 12rem;

      @include xs {
        max-inline-size: 50ch;
      }

      @include md {
        max-inline-size: 63ch;
      }
      @include lg {
        max-inline-size: 46ch;
        height: 16rem;
      }
    }

    &__tab-destance {
      width: 100%;
      border-top: 0.12rem solid map-get($all_colors, primary, 400);

      @include lg {
        // width: fit-content;
      }
    }
  }

  //  Crew
  &--crew {
    background-image: url('../../../assets/crew/background-crew-mobile.jpg');

    @include md {
      background-image: url('../../../assets/crew/background-crew-tablet.jpg');
    }

    @include lg {
      background-image: url('../../../assets/crew/background-crew-desktop.jpg');
    }

    &__tab-content {
      max-height: min-content;
      @include md {
        flex-direction: column-reverse;
      }

      @include lg {
        flex-direction: row-reverse;
      }
    }
    &__tab-img-wrapper {
      min-width: 177px;
      min-height: 220px;
      max-height: min-content;
      img {
        width: 177px;
        height: 220px;
        filter: drop-shadow(6px 10px 6px rgba(0, 0, 0, 0.25));
      }
      @include md {
        min-width: 400px;
        min-height: 450px;

        img {
          width: 400px;
          height: 450px;
        }
      }
    }

    &__tab-text-content {
      @include md {
        & > *:first-child {
          order: 9;
        }
      }
    }

    &__tab-description {
      max-inline-size: 39ch;
      height: 12rem;

      @include xs {
        max-inline-size: 50ch;
      }

      @include md {
        max-inline-size: 55ch;
      }
      @include lg {
        max-inline-size: 44ch;
        height: 16rem;
      }
    }
  }

  &--technology {
    background-image: url('../../../assets/technology/background-technology-mobile.jpg');

    @include md {
      background-image: url('../../../assets/technology/background-technology-tablet.jpg');
    }

    @include lg {
      background-image: url('../../../assets/technology/background-technology-desktop.jpg');
    }

    &__tab-content {
      @include lg {
        flex-direction: row-reverse;
      }
    }

    &__tab-img-wrapper {
      justify-content: flex-end;
      width: 100%;
      min-width: 100%;
      min-height: 200px;

      @include xs {
        min-height: 320px;
      }

      @include md {
        min-height: 400px;
      }

      img {
        width: 100%;
      }

      @include lg {
        min-width: 400px;
        min-height: 380px;

        img {
          width: 400px;
        }
      }

      @include xl {
        min-width: 515px;
        min-height: 350px;
        img {
          width: 515px;
        }
      }
    }

    &__tab-text-content {
      width: 100%;
    }

    &__tab-description {
      max-inline-size: 39ch;
      height: 12rem;

      @include xs {
        max-inline-size: 50ch;
      }

      @include lg {
        max-inline-size: 44ch;
        height: 16rem;
      }
    }
  }
}
