:root {
  --ff-serif: 'Bellefair', serif;
  --ff-sans-cond: 'Barlow Condensed', sans-serif;
  --ff-sans-normal: 'Barlow', sans-serif;

  --base-letter-spc: 1px;
  --base-sizing: 0.5rem;
  --base-gap: calc(var(--base-sizing) * 2);
  --base-transition-duration: 0.475s;
  --base-animiation-Timing: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --main-nav-width: 67.7vw;
  --main-nav-height: 100vh;
  --main-navlist-width: calc(var(--main-nav-width) * 0.93);
  --main-navlist-height: calc(var(--main-nav-height) * 0.2);

  --indicatorHeight: calc((var(--main-navlist-height)) / 4);
  --indicatorWidth: calc((var(--main-navlist-width)) / 4);
  --indicatorWidth-after: var(--indicatorWidth);
  --indicatorPadding-after: 0;

  --indicatorWidth-desktop: calc(var(--main-navlist-width) * 0.8 / 4);
  --indicatorWidth-after-desktop: var(--indicatorWidth-desktop);
  --indicatorPadding-after-desktop: 0;

  --secondary-navlist-width: 285px;
  --indicator-width-second-nav: calc(var(--secondary-navlist-width) / 4);
  --indicator-width-second-nav-desktop: calc(
    var(--secondary-navlist-width-desktop) / 4
  );

  // --indicatorWidth-Desktop: calc((var(--main-navlist-width) * 0.8) / 4);
}

// ============================================== */
// ===============> Breakpoints                  */

$breakpoints: (
  '': 0,
  xs-: 480px,
  md-: 768px,
  lg-: 992px,
  xl-: 1440px,
  xxl-: 1600px,
);

// ============================================== */
// ===============> Colors                       */

$all_colors: (
  primary: (
    100: rgba(255, 255, 255, 1),
    200: rgba(255, 255, 255, 0.5),
    300: rgba(255, 255, 255, 0.35),
    400: rgba(255, 255, 255, 0.17),
    500: rgba(255, 255, 255, 0.1),
    600: rgba(255, 255, 255, 0.04),
  ),
  secondary: (
    100: rgba(208, 214, 249),
  ),
  tertiary: (
    100: rgb(11, 13, 23),
  ),
);

// ============================================== */
// ===============> Fonts                        */

$font_size_map: (
  fs-900: (
    xs: 15rem - 7rem,
    md: 15rem,
    xl: 15rem,
  ),
  fs-800: (
    xs: 10rem - 4.4rem,
    md: 10rem - 2rem,
    xl: 10rem,
  ),
  fs-700: (
    xs: 5.6rem - 3.2rem,
    md: 5.6rem - 1.6rem,
    xl: 5.6rem,
  ),
  fs-600: (
    xs: 3.2rem - 0.8rem,
    md: 3.2rem - 0.4rem,
    xl: 3.2rem,
  ),
  fs-500: (
    xs: 2.8rem - 1.2rem,
    md: 2.8rem - 0.8rem,
    xl: 2.8rem,
  ),
  fs-400: (
    xs: 1.4rem,
    xl: 1.8rem,
  ),
  fs-300: (
    xs: 1.4rem,
    xl: 1.6rem,
  ),
  fs-200: (
    xs: 1.4rem,
  ),
);

$font_weight_map: (
  fw-700: 700,
  fw-400: 400,
);

$font_family_map: (
  ff-primary: var(--ff-serif),
  ff-secondary: var(--ff-sans-cond),
  ff-tertiary: var(--ff-sans-normal),
);

$letter_spc_map: (
  1: var(--base-letter-spc),
  2: calc(var(--base-letter-spc) * 2),
  3: calc(var(--base-letter-spc) * 3),
  4: calc(var(--base-letter-spc) * 4),
  5: calc(var(--base-letter-spc) * 5),
  6: calc(var(--base-letter-spc) * 6),
  7: calc(var(--base-letter-spc) * 7),
  8: calc(var(--base-letter-spc) * 8),
  9: calc(var(--base-letter-spc) * 9),
  10: calc(var(--base-letter-spc) * 10),
  0: 0,
);
// ============================================== */
// ===============> Layouts                      */
$directions_map: (
  l: left,
  r: right,
  t: top,
  b: bottom,
);

$base_sizing_map: (
  1: var(--base-sizing),
  2: calc(var(--base-sizing) * 2),
  3: calc(var(--base-sizing) * 3),
  4: calc(var(--base-sizing) * 4),
  5: calc(var(--base-sizing) * 5),
  6: calc(var(--base-sizing) * 6),
  7: calc(var(--base-sizing) * 7),
  8: calc(var(--base-sizing) * 8),
  9: calc(var(--base-sizing) * 9),
  10: calc(var(--base-sizing) * 10),
  0: 0,
);

$grid_col_map: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

$base_gap_map: (
  0: 0,
  1: var(--base-gap),
  2: calc(var(--base-gap) * 2),
  3: calc(var(--base-gap) * 3),
  4: calc(var(--base-gap) * 4),
  5: calc(var(--base-gap) * 5),
  6: calc(var(--base-gap) * 6),
  7: calc(var(--base-gap) * 7),
  8: calc(var(--base-gap) * 8),
  9: calc(var(--base-gap) * 9),
  10: calc(var(--base-gap) * 10),
);
