@use 'variables' as vars;

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Bellefair&family=Open+Sans:ital,wght@0,500;0,600;0,700;1,400&display=swap');

/* -------> Resets                    */
/* --------------------------------- */

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0rem;
  }
}
html:focus-within {
  scroll-behavior: smooth;
}

body {
  line-height: 1.45;
  text-rendering: optimizeSpeed;
  font-family: var(--ff-sans-normal);
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
  font: inherit;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}
p {
  line-height: 1.799;
}
button,
a {
  cursor: pointer;
}
